.navbar-right {
  margin-left: auto;
}

.navbar-left {
  margin-right: auto;
}

.navbar-brand img {
  padding-left: 10px;
}

.navbar-brand span {
  margin-left: 20px;
}

.card-body table {
  margin-bottom: 0;
}

.card-body .table-responsive {
  padding-bottom: 0;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 5px;
  color: #333;
}

.card-title-with-icon {
  display: flex;
  justify-content: space-between;
}

.chevron-icon {
  margin-left: auto;
  font-size: 12px;
  cursor: pointer; /* Change the cursor to a hand when hovering */
  text-decoration: underline; /* Add an underline to make it look like a link */
  transition: color 0.2s; /* Add a smooth color transition */
}

.chevron-icon:hover {
  color: blue;
}

.card {
  margin-bottom: 5px;
}

.card-red-border {
  border-color: red;
  border-width: 5px;
  margin: 0px;
  margin-bottom: 10px;
}

.card-blue-border {
  border-color: blue;
  border-width: 5px;
  margin: 0px;
  margin-bottom: 10px;
}

.bold-border-card {
  transition: border 0.5s; /* Add a transition for a smooth effect */
}

.bold-border-card:focus {
  border: 2px solid bold; /* Specify the bold border style */
}

.card-footer-link {
  cursor: pointer;
  text-decoration: underline;
}

.card-footer-link:hover {
  color: blue;
}

.custom-card {
  margin-bottom: 5px; /* Adjust the margin as needed to control the spacing between cards */
}

.link-text {
  text-decoration: underline; /* Underline the text */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.link-text:hover {
  color: blue; 
}

.special-row {
  color: blue !important; /* Use !important to increase specificity */
  background-color: transparent; /* Set the background color if needed */
}

.regular-row {
  color: #333;
  background-color: transparent; /* Set the background color if needed */
}

.highlight-element {
  color: blue !important; /* Use !important to increase specificity */
  background-color: transparent; /* Set the background color if needed */
}

.blue-arrow {
  color: blue !important; /* Set the color to blue */
  cursor: pointer; /* Add pointer cursor to indicate clickability */
}

.card-title-italic {
  font-style: italic;
}

.card-content-italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  color: blue; /* Adjust color as needed */
}

@media print {
  /* Hide the Navbar and the Print button */
  .navbar, button {
    display: none !important;
  }

  /* Adjust container padding for print */
/*   .container {
    padding: 0;
    margin: 0;
  } */

  /* Optional: Set font sizes for print */
  body {
    font-size: 12pt;
  }
}

.button-spacing .btn {
  margin-right: 10px; /* Adjust the spacing between buttons */
}

.button-spacing .btn:last-child {
  margin-right: 0; /* Ensure no extra margin on the last button */
}


